<!--/* eslint-disable vue/no-v-html */-->
<template>
  <div class="layout-title">
    <div class="layout-title__content">
      <h3 class="layout-title__title big-title">
        <slot name="title">{{ $t('title') }}</slot>
      </h3>
      <div class="layout-title__input">
        <label class="layout-title__input-length">Длина лога</label>
        <base-input v-model="length" class="layout-title__input-input"> </base-input>
        <plain-button color="primary" class="layout-title__input-length" @click="fetch(length)"
          >Отправить</plain-button
        >
        <!--        <plain-button color="primary" class="layout-title__input-length" @click="fetch('full')"-->
        <plain-button color="del" class="layout-title__input-length" @click="fetch()"
          >Весь лог</plain-button
        >
      </div>
      <div class="layout-title__specs">
        <base-loader v-if="isLoading" />
        <main-card v-else class="top">
          <div class="base-message">
            <p class="standart-text base-message__item">{{ output }}</p>
          </div>
        </main-card>
      </div>
    </div>
    <!--    <page-block :title="$t('title')">-->
    <!--      <base-loader v-if="isLoading" />-->
    <!--      <main-card v-else>-->
    <!--        <div class="base-message">-->
    <!--          <p class="standart-text base-message__item">{{ output }}</p>-->
    <!--        </div>-->
    <!--      </main-card>-->
    <!--    </page-block>-->
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import { OPTariff } from '@/models/OP/OPTariff';
import BaseInput from '@/components/BaseInput/BaseInput';
export default {
  name: 'Log',
  components: { BaseInput, MainCard },
  mixins: [billMgrToolActions],
  props: {
    tariff: {
      type: OPTariff,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {
      output: '',
      length: 25,
      isLoading: true,
    };
  },
  computed: {},
  created() {
    // console.log('--created--');
    this.fetch(this.length);
  },
  methods: {
    fetch(length) {
      this.isLoading = true;
      const action = {
        'os-getConsoleOutput': {
          // length: length,
        },
      };
      // if (length !=='full') action['os-getConsoleOutput'].length = length;
      if (length) action['os-getConsoleOutput'].length = length;
      this.sendServerAction(JSON.stringify(action))
        .then(data => {
          // console.log(data.output);
          this.output = data.output;
          // return this.fillWhois(data.output);
        })
        .catch(e => this.showError(e))
        .finally(() => {
          // this.isLoading = false;
          setTimeout(() => (this.isLoading = false), 300);
        });
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/getServerLog', {
        id: this.tariff.id,
        params,
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Лог консоли сервера"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.layout-title {
  min-width: calc(100% - 260px - 1.5rem);
  flexy(flex-start, center);

  &__img {
    flex: 0 0 2.5rem;
    margin-right: 1.25rem;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__content {
    flexy(flex-start, center, wrap);
    min-width: calc(100% - 260px - 1.5rem);

    &::after {
      +breakpoint(sm-and-up) {
        content: '';
        display: block;
        order: 3;
        flex: 1 1 100%;
      }
    }
  }

  &__title {
    flex: 0 0 calc(100% - 4rem);
    max-width: calc(100% - 4rem);
    order: 1;
    margin-bottom: 1rem;
    word-break: break-word;

    +breakpoint(sm-and-up) {
      flex: 1 0 100%;
      max-width: calc(100% - 3rem);
      padding-right: 0;
      margin-bottom: 0.25rem;
    }

    +breakpoint(md-and-up) {
      flex: 1 0 auto;
      max-width: 100%;
    }
  }

  &__btn {
    flex: 0 0 auto;
    order: 2;
    margin: 0 0 0 auto;
    align-self: flex-start;
  }

  &__input {
    display flex
    align-items: center;
    flex: 0 0 auto;
    order: 3;
    margin: -0.25rem -0.25rem 1rem;

    +breakpoint(sm-and-up) {
      margin-top: 0.5rem;
    }

    +breakpoint(md-and-up) {
      flex: 0 0 auto;
      order: 5;
      margin: -0.25rem 1rem 0;
    }
    +breakpoint(lg-and-up) {
      order: 3;
    }
    &-length {
      //max-width: 40px;
      padding-left :0.75rem;
    }
    &-input {
      padding-left :0.75rem;
      width: 9rem;
    }

  }

  &__status {
    margin: 0.25rem;
  }

  &__address {
    order: 3;
    flex: 0 0 auto;
    margin: 0 auto 0 0;

    +breakpoint(sm-and-up) {
      order: 4;
      margin: 0 1.25rem 0 0;
    }
  }

  &__specs {
    flex: 0 0 100%;
    min-width: calc(100% - 260px - 1.5rem);
    margin-top: 1.5rem;
    order: 5;

    +breakpoint(sm-and-up) {
      flex: 1 1 auto;
      margin-top: 0;
    }
  }

}
  .top {
    margin-top: 1rem;
  }
.domain-detail-whois {
  &__content {
  }
}
.base-message {
  //overflow : hidden;
  white-space: break-spaces;
  overflow: auto;
  &__item {
    white-space: break-spaces;
  }
}
</style>
